import BugsnagReact from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

BugsnagReact.start({
  appVersion: '1.0.0',
  apiKey: '3321e32b0364727c2f347175cc9047e3',
  plugins: [new BugsnagPluginReact()],
  endpoints: {
    notify: 'https://bugsnag-events.omadahealth.com',
    sessions: 'https://bugsnag-sessions.omadahealth.com',
  },
});

declare global {
  const Bugsnag;
}

if (typeof Bugsnag !== 'undefined' && Bugsnag && Bugsnag.getUser().id) {
  BugsnagReact.setUser(Bugsnag.getUser().id);
}

const ErrorBoundary =
  BugsnagReact.getPlugin('react')!.createErrorBoundary(React);

const ErrorView: React.FunctionComponent = () => (
  <Container>
    <Row>
      <Col xs={12} className="d-flex align-items-center flex-column">
        <div className="my-4">
          <h2>Bleep borp! Sorry, something went wrong.</h2>
        </div>
        <h2 className="mb-4">
          <i className="fa fa-hand-peace-o" aria-hidden="true" />
        </h2>
        <h4>
          Our engineers have been notified and are investigating the issue.
        </h4>
      </Col>
    </Row>
  </Container>
);

const BugsnagBoundary: React.FunctionComponent = ({ children }) => (
  <ErrorBoundary FallbackComponent={ErrorView}>{children}</ErrorBoundary>
);

export default BugsnagBoundary;
