import { createSelector } from 'reselect';
import _ from 'lodash';
import Decimal from 'decimal.js-light';

import {
  createConnect,
  createReducers,
  actionDelete,
  actionReplace,
  actionReplaceAll,
} from './Common';

import { del, get, post } from '../api/ajax';
import { Fetchers } from '../lib';
import { CurrentUserState } from './CurrentUser';

export const parseBillingEvents = (events): object =>
  _.mapValues(events, providerEvents => ({
    ...providerEvents,
    total_owed: new Decimal(providerEvents.total_owed),
    total_paid: new Decimal(providerEvents.total_paid),
    total_due: new Decimal(providerEvents.total_due),
    episodes: _.mapValues(providerEvents.episodes, episodeEvents =>
      _.map(episodeEvents, event => ({
        ...event,
        amount_paid: new Decimal(event.amount_paid),
        amount_due: new Decimal(event.amount_due),
      })),
    ),
  }));

export const billingFetchers = {
  CURRENT_USER: {
    fetch: () =>
      get({
        url: '/api/user/me',
      }).then(results => actionReplace(results.id, results)),
  },
  PAYABLE_EVENTS: {
    fetch: () =>
      get({
        url: '/api/payable/contractor',
      }).then(results => actionReplaceAll(parseBillingEvents(results))),
    fetchEmployeePayableEvents: () =>
      get({
        url: '/api/payable',
      }).then(results => actionReplaceAll(parseBillingEvents(results))),
    remit: ({ providerId, data, onSuccess }) =>
      post({
        url: `/api/payable/provider/${providerId}/remittance`,
        data,
      }).then(results =>
        actionReplace(
          providerId,
          parseBillingEvents(results)[providerId],
          onSuccess,
        ),
      ),
  },
  CLAIMS: {
    fetch: () =>
      get({
        url: '/api/claim',
      }).then(results => actionReplaceAll(_.keyBy(results, 'id'))),
  },
  CLAIM: {
    fetch: ({ id, action }) =>
      get({
        url: `/api/claim/${id}`,
        data: { action },
      }).then(results => actionReplaceAll(results)),
    recordClaimPayment: ({
      id,
      versionId,
      paymentAmount,
      checkNumber,
      checkDate,
      claimNumber,
      onSuccess,
    }) =>
      post({
        url: `/api/claim/${id}/version/${versionId}/payment`,
        data: JSON.stringify({
          payment_amount: paymentAmount,
          check_number: checkNumber,
          check_date: checkDate,
          claim_number: claimNumber,
        }),
      }).then(results => actionReplaceAll(results, onSuccess)),
  },
  INVOICES: {
    fetch: () =>
      get({
        url: '/api/invoice',
      }).then(results => actionReplaceAll(_.keyBy(results, 'id'))),
    remitInvoicePayment: ({ id, amount_paid, paid_by, onSuccess }) =>
      post({
        url: `/api/invoice/${id}/payment`,
        data: JSON.stringify({ amount_paid, paid_by }),
      }).then(result => actionReplace(result.id, result, onSuccess)),
  },
  INVOICE: {
    fetch: ({ id, action }) =>
      get({
        url: `/api/invoice/${id}`,
        data: { action },
      }).then(results => actionReplaceAll(results)),
    create: ({ data, onSuccess }) =>
      post({
        url: '/api/invoice',
        data,
      }).then(results => actionReplaceAll(results, onSuccess)),
    validateClaim: ({ id, isReplacement, originalClaimId }) =>
      post({
        url: `/api/invoice/${id}/validate`,
        data: JSON.stringify({ isReplacement, originalClaimId }),
      }).then(results => actionReplaceAll(results)),
    claimStatus: ({ id, isReplacement, originalClaimId, onError }) =>
      post({
        url: `/api/invoice/${id}/status`,
        data: JSON.stringify({ isReplacement, originalClaimId }),
      })
        .then(results => actionReplaceAll(results))
        .catch(err => (onError ? onError(err) : Promise.reject(err))),
    submitClaim: ({ id, isReplacement, originalClaimId }) =>
      post({
        url: `/api/invoice/${id}/submit`,
        data: JSON.stringify({ isReplacement, originalClaimId }),
      }).then(results => actionReplaceAll(results)),
    deleteClaim: ({ id, onSuccess }) =>
      del({
        url: `/api/invoice/${id}`,
      }).then(() => actionDelete(id, onSuccess)),
  },
  CLAIM_ORGANIZATIONS: {
    fetch: (data = {}) =>
      get({
        url: '/api/claim/organization',
        data,
      }).then(results => actionReplaceAll(_.keyBy(results.results, 'id'))),
  },
  INVOICE_ORGANIZATIONS: {
    fetch: (data = {}) =>
      get({
        url: '/api/invoice/organization',
        data,
      }).then(results => actionReplaceAll(_.keyBy(results.results, 'id'))),
  },
  BILLING_ACCOUNTS: {
    fetch: data =>
      get({
        url: '/api/billing_account',
        data,
      }).then(results => actionReplaceAll(results)),
  },
  BILLING_ACCOUNT: {
    fetch: ({ id }) =>
      get({
        url: `/api/billing_account/${id}`,
      }).then(results => actionReplaceAll(results)),
  },
  BILLS: {
    fetch: () =>
      get({
        url: '/api/bill',
      }).then(results => actionReplaceAll(results)),
  },
  BILL: {
    create: data =>
      post({
        url: '/api/bill',
        data: JSON.stringify(data),
      }),
    send: ({ id }) =>
      post({
        url: `/api/bill/${id}/send`,
      }),
    fetch: ({ id }) =>
      get({
        url: `/api/bill/${id}`,
      }).then(results => actionReplaceAll(results)),
  },
  BILL_ACCESS_CODE: {
    create: ({ id, data }) =>
      post({
        url: `/user/bills/${id}/access_code`,
        data: JSON.stringify(data),
      }),
  },
  MANUAL_ADJUSTMENT: {
    create: ({ episodeID, adjustmentType, reason, amount, claimID }) =>
      post({
        url: `/api/episode/${episodeID}/receivable`,
        data: JSON.stringify({
          amount_due: amount,
          adjustment_type: adjustmentType,
          reason,
          claim_id: claimID,
        }),
      }),
  },
  PAYMENT_RECEIVABLE: {
    create: data =>
      post({
        url: '/api/payments/record',
        data: JSON.stringify(data),
      }),
  },
};

const wrappedFetchers = Fetchers.withFlashErrorsForAll(billingFetchers);

export const Connect = createConnect(wrappedFetchers, {
  currentUser: createSelector(
    [(state: CurrentUserState) => state.currentUserByID],
    currentUserByID => _.head(_.values(currentUserByID)),
  ),
});
export const Reducers = createReducers(wrappedFetchers);
