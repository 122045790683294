/* eslint-disable @typescript-eslint/no-explicit-any */

import Bugsnag from '@bugsnag/js';
import { isAjaxError } from '../api/ajax';

export type Fetcher = (...args: any) => Promise<any>;
export type Block = Record<string, Fetcher>;
export type ByResource = Record<string, Block>;
export type FetchErrorWrapper = (
  resourceName: string,
  fetcherName: string,
  fetcher: Fetcher,
) => Fetcher;

const logError = (error: unknown): void => {
  if (isAjaxError(error)) {
    console.warn({ msg: 'AJAX error', ...error });
  } else {
    console.warn({ msg: 'unexpected error', error });
  }
};

/**
 * Transform uncaught errors into meaningful flash messages.
 * @param resourceName
 * @param operation
 * @param fetcher
 */
export const messagingErrorHandler = (
  resourceName: string,
  operation: string,
  fetcher: Fetcher,
): Fetcher => {
  const friendlyOp = operation.startsWith('fetch')
    ? 'loading'
    : operation.startsWith('save')
    ? 'saving'
    : 'updating';
  const friendlyResourceName = resourceName.toLowerCase().split('_').join(' ');
  const message = `Problem ${friendlyOp} ${friendlyResourceName}.`;
  return (...args) =>
    fetcher(...args).catch(err => {
      Bugsnag.notify(new Error(message), e =>
        e.addMetadata('fetcherData', { error: err }),
      );
      const style = isAjaxError(err) && err.code < 500 ? 'warning' : 'danger';
      logError(err);
      flashMessenger.handleAdd(style, message);
    });
};

/**
 * Map error handling to a block of fetchers
 * @param resourceName
 * @param fetchers
 * @param errorHandler
 */
const mapErrorhandlingToFetchers = (
  resourceName: string,
  fetchers: Block,
  errorHandler: FetchErrorWrapper,
): Block =>
  Object.keys(fetchers).reduce(
    (acc, name) => ({
      ...acc,
      [name]: errorHandler(resourceName, name, fetchers[name]),
    }),
    {},
  );

/**
 * Wrap each fetcher in each block of resource blocks with some error handler
 * @param resources
 * @param errorHandler
 */
const mapErrorHandlingToResources = (
  resources: ByResource,
  errorHandler: FetchErrorWrapper,
): Block =>
  Object.keys(resources).reduce(
    (resourceAcc, resourceName) => ({
      ...resourceAcc,
      [resourceName]: mapErrorhandlingToFetchers(
        resourceName,
        resources[resourceName],
        errorHandler,
      ),
    }),
    {},
  );

/**
 * Wrap each fetcher in each block of resource blocks with flash message error handling
 * @param resources
 */
export const withFlashErrorsForAll = (resources: ByResource): Block =>
  mapErrorHandlingToResources(resources, messagingErrorHandler);

/**
 * Wrap a block of resource fetchers with flash message error handling
 * @param resources
 */
export const withFlashErrors = (resourceName: string, fetchers: Block): Block =>
  mapErrorhandlingToFetchers(resourceName, fetchers, messagingErrorHandler);
