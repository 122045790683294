export const FlashStyle = {
  success: 'success',
  danger: 'danger',
  warning: 'warning',
};

declare global {
  let flashMessenger: {
    handleAdd: (
      style: 'success' | 'danger' | 'warning',
      message: string | JSX.Element,
      autoClose?: boolean,
      displaytime?: number,
    ) => void;

    addSuccess: (
      message: string | JSX.Element,
      autoClose?: boolean,
      displaytime?: number,
    ) => void;

    addDanger: (
      message: string | JSX.Element,
      autoClose?: boolean,
      displaytime?: number,
    ) => void;

    addWarning: (
      message: string | JSX.Element,
      autoClose?: boolean,
      displaytime?: number,
    ) => void;
  };
}

export default flashMessenger;
