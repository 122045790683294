import { useState } from 'react';

export enum StoragePrefix {
  FEATURE = 'phys.feature',
  MESSAGE = 'phys.message',
  CLIENT_UI_STATE = 'phys.client-ui-state',
}

export const setLocalStorage = (
  prefix: StoragePrefix,
  key: string,
  val: string,
): void => {
  switch (prefix) {
    case StoragePrefix.CLIENT_UI_STATE:
      return localStorage.setItem(`${prefix}.${key}`, val);
    default:
      return sessionStorage.setItem(`${prefix}.${key}`, val);
  }
};

export const getLocalStorage = (
  prefix: StoragePrefix,
  key: string,
): string | null => {
  switch (prefix) {
    case StoragePrefix.CLIENT_UI_STATE:
      return localStorage.getItem(`${prefix}.${key}`);
    default:
      return sessionStorage.getItem(`${prefix}.${key}`);
  }
};

export function useLocalStorage(
  prefix: StoragePrefix,
  key: string,
  defaultValue: string | undefined = undefined,
): [string | undefined, (newValue: string) => void] {
  const [value, setValue] = useState(
    getLocalStorage(prefix, key) || defaultValue,
  );

  const setLocalStorageValue = (newValue: string): void => {
    setLocalStorage(prefix, key, newValue);
    setValue(getLocalStorage(prefix, key) || undefined);
  };

  return [value, setLocalStorageValue];
}

export function useLocalStorageFlag(
  prefix: StoragePrefix,
  key: string,
  defaultValue = false,
): [boolean, (newValue: boolean) => void] {
  const [value, setValue] = useLocalStorage(
    prefix,
    key,
    defaultValue ? 'true' : 'false',
  );

  const setBoolValue = (newValue: boolean): void => {
    setValue(newValue ? 'true' : 'false');
  };

  return [value === 'true', setBoolValue];
}
