import _ from 'lodash';
import React from 'react';
import Loader from 'react-loader';
import { connect } from 'react-redux';
import * as redux from 'redux';
import promiseMiddleware from 'redux-promise-middleware';

// FIXME: Error handling!

export function createStore(reducers) {
  return redux.applyMiddleware(promiseMiddleware())(redux.createStore)(
    reducers,
  );
}

export function wrapReduxComponent(Component, dependencies) {
  class WrapperComponent extends React.Component {
    static Component = Component;

    constructor(props) {
      super(props);
      this.state = {
        fetchStarted: false,
        loaded: false,
      };
    }

    componentDidMount() {
      _.forEach(dependencies, (args, key) => {
        const fetchName = `fetch${_.upperFirst(key)}`;
        if (!this.props[fetchName]) {
          console.error(`${fetchName} is not a valid fetcher.`);
        } else {
          this.props[fetchName](args(this.props));
        }
      });
      this.setState({
        fetchStarted: true,
      });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if (
        prevState.loaded ||
        !prevState.fetchStarted ||
        !nextProps.fetchState
      ) {
        return {};
      }
      const isFetching = _.some(
        _.keys(dependencies),
        key => nextProps.fetchState[key],
      );
      if (!isFetching) {
        return { loaded: true };
      }
      return {};
    }

    render() {
      return (
        <Loader loaded={this.state.loaded}>
          <Component {...this.props} />
        </Loader>
      );
    }
  }
  return WrapperComponent;
}

function getStateForFetchers(state, fetchers, selectors) {
  const newState = _.reduce(
    fetchers,
    (result, val, key) => {
      if (!_.has(state, key)) {
        return result;
      }
      result.isFetching = result.isFetching || state[key].isFetching;
      result.isSaving = result.isSaving || state[key].isSaving;
      result.fetchState = result.fetchState || {};
      result.fetchState[_.camelCase(key)] = state[key].isFetching;
      result.saveState = result.saveState || {};
      result.saveState[_.camelCase(key)] = state[key].isSaving;
      result[`${_.camelCase(key)}ByID`] = state[key].data;
      return result;
    },
    _.assign({ isFetching: false, isSaving: false }, state.CONTAINER),
  );

  return _.assign(
    newState,
    _.mapValues(selectors, selector => selector(newState)),
  );
}

export function createConnect(fetchers, selectors) {
  return (Component, dependencies) =>
    connect(
      state => getStateForFetchers(state, fetchers, selectors),
      dispatch =>
        _.reduce(
          fetchers,
          (result, val, key) => {
            _.forEach(val, (func, type) => {
              const isPredefinedType =
                type === 'fetch' ||
                type === 'save' ||
                type === 'create' ||
                type === 'delete';
              const resultKey = isPredefinedType
                ? `${type}${_.upperFirst(_.camelCase(key))}`
                : type;
              const actionType = isPredefinedType
                ? `${type.toUpperCase()}_${key}`
                : `SAVE_${key}`;
              result[resultKey] = args =>
                dispatch(
                  _.assign({
                    type: actionType,
                    payload: func(args),
                  }),
                );
            });
            return result;
          },
          { setState: data => dispatch({ type: 'SET_CONTAINER_STATE', data }) },
        ),
      undefined,
      // { withRef: true },
    )(dependencies ? wrapReduxComponent(Component, dependencies) : Component);
}

function processReduceCommands(
  state,
  commands,
  stateIndicator,
  executionContext,
) {
  let newState = _.assign({}, state, { [stateIndicator]: false });

  _.forEach(commands, ({ command, context, id, func, data, onSuccess }) => {
    if (context !== executionContext) {
      return;
    }
    if (!command) {
      return;
    }

    switch (command) {
      case 'replaceAll':
        newState = _.assign(newState, { data });
        break;
      case 'replace':
        newState = _.assign(newState, {
          data: _.assign({}, newState.data, { [id]: data }),
        });
        break;
      case 'update':
        newState = _.assign(newState, {
          data: _.assign({}, newState.data, {
            [id]: _.assign({}, newState.data[id], data),
          }),
        });
        break;
      case 'updateAll':
        newState = {
          ...newState,
          data: {
            ...newState.data,
            ...data,
          },
        };
        break;
      case 'delete':
        newState = _.assign(newState, { data: _.omit(newState.data, id) });
        break;
      case 'function':
        newState = {
          ...newState,
          data: {
            ...newState.data,
            [id]: func(newState.data[id]),
          },
        };
        break;
      case 'concat':
        if (_.size(data) > 0) {
          newState = {
            ...newState,
            data: {
              ...newState.data,
              [id]: [...newState.data[id], ...data],
            },
          };
        }
        break;
      default:
        console.error('Unhandled command:', command);
    }
    if (onSuccess) {
      onSuccess(data);
    }
  });

  return newState;
}

export function createReducers(fetchers, containerState) {
  const containerReducer = (state, action) => {
    if (typeof state === 'undefined') {
      return _.assign({}, containerState);
    }
    switch (action.type) {
      case 'SET_CONTAINER_STATE':
        return _.assign({}, state, action.data);
      default:
        return state;
    }
  };

  return redux.combineReducers(
    _.set(
      _.mapValues(fetchers, (val, key) => (state, action) => {
        if (typeof state === 'undefined') {
          return {
            isFetching: false,
            isSaving: false,
            data: {},
          };
        }

        let payload = [];

        switch (action.type) {
          case `FETCH_${key}_PENDING`:
            return _.assign({}, state, { isFetching: true });
          case `SAVE_${key}_PENDING`:
          case `CREATE_${key}_PENDING`:
          case `DELETE_${key}_PENDING`:
            return _.assign({}, state, { isSaving: true });
          case `FETCH_${key}_FULFILLED`:
            payload = action.payload ? _.castArray(action.payload) : [];
            return processReduceCommands(state, payload, 'isFetching');
          case `SAVE_${key}_FULFILLED`:
          case `CREATE_${key}_FULFILLED`:
          case `DELETE_${key}_FULFILLED`:
            payload = action.payload ? _.castArray(action.payload) : [];
            return processReduceCommands(state, payload, 'isSaving');
          default:
            if (action.payload) {
              return processReduceCommands(
                state,
                _.castArray(action.payload),
                'isSaving',
                _.camelCase(key),
              );
            }
            return state;
        }
      }),
      'CONTAINER',
      containerReducer,
    ),
  );
}

/**
 * Convenience functions for doing redux updates.
 */

// Replaces a single entry in the store.
export function actionReplace(id, data, onSuccess, onFailure) {
  return {
    command: 'replace',
    id,
    data,
    onSuccess,
    onFailure,
  };
}

// Replaces all elements in the store.
export function actionReplaceAll(data, onSuccess) {
  return {
    command: 'replaceAll',
    data,
    onSuccess,
  };
}

// Updates a single entry in the store.  Keys not in data have their old values retained.
export function actionUpdate(id, data, onSuccess) {
  return {
    command: 'update',
    id,
    data,
    onSuccess,
  };
}

// Updates all elements in the store.  Like replace but keys not in data will be retained.
export function actionUpdateAll(data, onSuccess, ids = []) {
  return {
    command: 'updateAll',
    data,
    onSuccess,
    ids,
  };
}

// Delete a single entry from the store.
export function actionDelete(id, onSuccess) {
  return {
    command: 'delete',
    id,
    onSuccess,
  };
}

// Concatenate the results
export function actionConcat(id, data, onSuccess) {
  return {
    command: 'concat',
    id,
    data,
    onSuccess,
  };
}

// Custom function
export function actionFunction(id, data, func, onSuccess) {
  return {
    command: 'function',
    id,
    data,
    func,
    onSuccess,
  };
}

// Runs the action in a different context (i.e. reducer)
export function actionContext(context, action) {
  return _.assign({}, action, { context });
}
