import React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

/**
 * Similar to `withRouter` from react router v5 and earlier, make
 * hooks based router functionality available to classy components
 *
 * @param Component
 * @returns
 */
export function withRouterShim(Component): React.FC<any> {
  return props => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    // react router upgrade:
    const navigateInRenderHack = (route): any =>
      setTimeout(() => navigate(route));
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
        navigateInRenderHack={navigateInRenderHack}
      />
    );
  };
}
