import React from 'react';
import { createRoot } from 'react-dom/client';

export const renderFromRoot = (
  node: React.ReactNode,
  getAppRootEl = () => document.getElementById('app')!,
): void => {
  const root = createRoot(getAppRootEl());
  root.render(node);
};
