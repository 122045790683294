/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import queryString from 'query-string';
import { getActiveFlags } from '../lib/Feature';

export const buildUrl = (
  base: string,
  queryParameters: Record<string, boolean | string | undefined> = {},
): string => {
  if (_.isEmpty(queryParameters)) {
    return base;
  }
  const qryString = queryString.stringify(queryParameters);
  return `${base}?${qryString}`;
};

export default function ajax(params: JQueryAjaxSettings): void {
  const callback = Array.isArray(params.error) ? params.error[0] : params.error;
  params.error = (xhr, status, err) => {
    // redirect if logged out
    const res = xhr.responseJSON;
    if (_.has(res, 'data.redirect')) {
      const isLogin =
        res.data.redirect === '/login' || res.data.redirect === '/survey/login';
      const url = !isLogin
        ? res.data.redirect
        : `${res.data.redirect}?next=${encodeURIComponent(
            window.location.href,
          )}`;
      window.location.replace(url);
    }
    if (callback !== undefined) {
      callback(xhr, status, err);
    }
  };

  $.ajax(params);
}

export interface AjaxError {
  err: true;
  code: number;
  message: string;
}

function handleError(
  reject: (err: AjaxError) => void,
  xhr: JQuery.jqXHR,
): void {
  reject({
    code: xhr.status,
    message: xhr.responseText,
    err: true,
  });
}

export const isAjaxError = (t: any): t is AjaxError =>
  t != null && t.err === true && t.code != null;

function buildGatekeeperHeaders(): Record<string, string> {
  return {
    'X-Physera-Gatekeeper': getActiveFlags().join(','),
  };
}

function stripTrailingSlash(url: string): string {
  return url.replace(/\/$/, '');
}

type RestParams = JQueryAjaxSettings & {
  url: string;
};

const ajaxArgs = (
  method: string,
  params: RestParams,
  resolve,
  reject,
  extra: Partial<JQueryAjaxSettings> = {},
): JQueryAjaxSettings => ({
  dataType: 'json',
  headers: buildGatekeeperHeaders(),
  success: res => resolve(res),
  error: handleError.bind(null, reject),
  method,
  ...params,
  ...extra,
});

export function get<R = any>(params: RestParams): Promise<R> {
  params.url = stripTrailingSlash(params.url);
  return new Promise((resolve, reject) => {
    ajax(ajaxArgs('GET', params, resolve, reject, { cache: false }));
  });
}

export function post<R = any>(
  params: RestParams,
  extras: object = { cache: false, contentType: 'application/json' },
): Promise<R> {
  params.url = stripTrailingSlash(params.url);
  return new Promise((resolve, reject) => {
    ajax(ajaxArgs('POST', params, resolve, reject, extras));
  });
}

export function put<R = any>(
  params: RestParams,
  extras: object = { cache: false, contentType: 'application/json' },
): Promise<R> {
  params.url = stripTrailingSlash(params.url);
  return new Promise((resolve, reject) => {
    ajax(ajaxArgs('PUT', params, resolve, reject, extras));
  });
}

export function del<R = any>(params: RestParams): Promise<R> {
  params.url = stripTrailingSlash(params.url);
  return new Promise((resolve, reject) => {
    ajax(
      ajaxArgs('DELETE', params, resolve, reject, {
        cache: false,
        contentType: 'application/json',
      }),
    );
  });
}

export function patch<R = any>(
  params: RestParams,
  extras: object = { cache: false, contentType: 'application/json' },
): Promise<R> {
  params.url = stripTrailingSlash(params.url);
  return new Promise((resolve, reject) => {
    ajax(ajaxArgs('PATCH', params, resolve, reject, extras));
  });
}
