import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';

type ID_ = string | number | symbol;

type ContextStore<ID extends ID_ = number, T = unknown> = Record<ID, T>;
export interface BaseContext<T = unknown, ID extends ID_ = string> {
  get: (id: ID) => T;
  set: (id: ID, val: T) => void;
  has: (id: ID) => boolean;
  all: () => ContextStore<ID, T>;
}

export interface CrudContext {
  fetch: (args: object) => Promise<void>;
  create: (args: object) => Promise<void>;
  save: (args: object) => Promise<void>;
  delete: (args: object) => Promise<void>;
}

export const baseContextStub = {
  get: () => {
    throw new Error('Not implemented');
  },
  set: () => {
    throw new Error('Not implemented');
  },
  has: () => {
    throw new Error('Not implemented');
  },
  all: () => {
    throw new Error('Not implemented');
  },
};

export const crudContextStub = {
  fetch: () => {
    throw new Error('Not implemented');
  },
  create: () => {
    throw new Error('Not implemented');
  },
  save: () => {
    throw new Error('Not implemented');
  },
  delete: () => {
    throw new Error('Not implemented');
  },
};

export const buildContextValue = (
  store: ContextStore,
  reducers,
): BaseContext => ({
  ...reducers,
  get: id => store[id],
  set: (id, val) => {
    store[id] = val;
  },
  has: id => _.has(store, id),
  all: () => store,
});

export default function buildProvider(Context, buildReducers) {
  return ({ children }) => {
    const [store, setStore] = useState({});

    const actionReplace = useCallback((id, value, onSuccess) => {
      setStore(prevStore => ({ ...prevStore, [id]: value }));
      if (onSuccess) {
        onSuccess(value);
      }
    }, []);

    const actionReplaceAll = useCallback(values => {
      setStore(prevStore => ({ ...prevStore, ...values }));
    }, []);

    const actionUpdate = useCallback((id, value, onSuccess) => {
      setStore(prevStore => ({
        ...prevStore,
        [id]: { ...prevStore[id], ...value },
      }));
      if (onSuccess) {
        onSuccess(value);
      }
    }, []);

    const actionDelete = useCallback((id, onSuccess) => {
      setStore(prevStore => ({ ..._.omit(prevStore, [id]) }));
      if (onSuccess) {
        onSuccess();
      }
    }, []);

    const actionDeleteKey = useCallback((id, key, onSuccess) => {
      setStore(prevStore => ({
        ...prevStore,
        [id]: _.omit(prevStore[id], key),
      }));
      if (onSuccess) {
        onSuccess();
      }
    }, []);

    const reducers = useMemo(
      () =>
        buildReducers({
          actionReplace,
          actionReplaceAll,
          actionUpdate,
          actionDelete,
          actionDeleteKey,
        }),
      [
        actionReplace,
        actionReplaceAll,
        actionUpdate,
        actionDelete,
        actionDeleteKey,
      ],
    );

    const contextValue: BaseContext = buildContextValue(store, reducers);

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
  };
}
