import _ from 'lodash';
import { StoragePrefix, getLocalStorage, setLocalStorage } from './Storage';

/**
 * New Flag names should be dash delimited. Flags should generally
 * encapsulate one set of changes that will turned on or off
 * (e.g. 'released') simultaneously.
 */

export enum FeatureName {
  // Just a placeholder so that types can be inferred.
  CASE_COMPLEXITY = 'case_complexity',
  DUMMY = 'dummy',
  LEGACY_SHOULDER_ASSESSMENT = 'legacy-shoulder-assessment',
  MESSAGE_FILES = 'message-files',
  MINDFULNESS_ACTIVITY = 'mindfulness-activity',
  WEEKLY_WORKOUT_GOAL = 'weekly-workout-goal',
  DRAFT_ASSESSMENTS = 'draft-assessments',
  EXERCISE_RECOMMENDATIONS = 'exercise-recommendations',
  EXERCISE_VARIANTS = 'exercise-variants',
  SAFETY_DANCE = 'safety-dance',
}

export type ClientFeature =
  | 'activities'
  | 'education_videos'
  | 'education_stories'
  | 'mindfulness'
  | 'required_weekly_workout_goal';

// A list of features to enable by default.
const DEFAULT_FEATURES: FeatureName[] = [
  FeatureName.DUMMY,
  FeatureName.CASE_COMPLEXITY,
  FeatureName.MINDFULNESS_ACTIVITY,
  FeatureName.WEEKLY_WORKOUT_GOAL,
  FeatureName.EXERCISE_RECOMMENDATIONS,
  FeatureName.EXERCISE_VARIANTS,
];

const setLocalFeatureStorage = (name: FeatureName, val: boolean): void => {
  setLocalStorage(StoragePrefix.FEATURE, name, JSON.stringify(val));
};

const getLocalFeatureStorage = (name: FeatureName): boolean => {
  const stored = getLocalStorage(StoragePrefix.FEATURE, name);
  if (stored) {
    return Boolean(JSON.parse(stored));
  }
  return _.includes(DEFAULT_FEATURES, name);
};

export const getFeatureValue = (flag: FeatureName): boolean => {
  const search = new URLSearchParams(window.location.search);
  const urlVal = search.get(flag);
  if (urlVal) {
    const turnFlagOn = urlVal.toLowerCase() !== 'false';
    setLocalFeatureStorage(flag, turnFlagOn);
    return turnFlagOn;
  }
  return getLocalFeatureStorage(flag);
};

export const getActiveFlags = (): string[] =>
  _.values(_.pickBy(FeatureName, value => getFeatureValue(value)));

Object.keys(FeatureName).forEach(f => {
  console.debug(`${f}: ${getFeatureValue(FeatureName[f])}`);
});
